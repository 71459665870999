<template>
  <a-drawer
    title="分发记录"
    width="700"
    :visible="visible"
    :closable="false"
    :footer="false"
    @close="onClose"
  >
    <!-- 列表 -->
    <a-table
      class="table-view"
      :data-source="dataSource"
      :columns="columns"
      :row-key="record => record.id"
      :pagination="pagination"
      :loading="isLoading"
      @change="handleTableChange"
    >
      <!-- 自定义行内容 -->
      <template #bodyCell="{ column, record }">
        <!-- 分发渠道 -->
        <template v-if="column.key === 'name'">
          {{ record.project.name }}
        </template>
        <!-- 底价 -->
        <template v-if="column.key === 'money'">
          {{ $pub.KEEP_NUMBER_DECIMAL(record[column.key] / 100, 2) }}
        </template>
        <!-- 分成 -->
        <template v-if="column.key === 'cop_rate'">
          {{ record.cop_type === 2 ? record.cop_rate + '%'  : '-' }}
        </template>
        <!-- 操作 -->
        <template v-if="column.key === 'operation'">
          <a-button type="link" @click="touchEdit(record)" :disabled="!record.is_edit">编辑</a-button>
        </template>
      </template>
    </a-table>
    <!-- 分发 -->
    <DistributeDrawer ref="RefDistributeDrawer" @success="getDistributeList"></DistributeDrawer>
  </a-drawer>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { nextTick } from 'process'
import DistributeDrawer from './DistributeDrawer'
import { distributeList } from '@/api/copyright'

// 分发
let RefDistributeDrawer = ref(null)
// 显示
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 片库id
let dramaID = ref(undefined)
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '分发渠道',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '授权开始',
    dataIndex: 'copyright_start_at',
    key: 'copyright_start_at'
  },
  {
    title: '授权结束',
    dataIndex: 'copyright_end_at',
    key: 'copyright_end_at'
  },
  {
    title: '底价',
    dataIndex: 'money',
    key: 'money'
  },
  {
    title: '分成',
    dataIndex: 'cop_rate',
    key: 'cop_rate'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])

// 显示
function showDrawer (data) {
  // 显示
  visible.value = true
  // 片库id
  dramaID.value = data.id
  // 获取分发记录列表
  getDistributeList()
}

// 分发记录
function getDistributeList () {
  isLoading.value = true
  distributeList({
    drama_id: dramaID.value,
    page: pagination.current,
    page_size: pagination.pageSize
  }).then(res => {
    isLoading.value = false
    const { code, data } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
    }
  }).catch(() => {
    isLoading.value = false
  })
}

// 关闭
function onClose () {
  if (!isLoading.value) {
    // 重置
    pagination.current = 1
    pagination.pageSize = 10
    visible.value = false
  }
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getDistributeList()
}

// 编辑
function touchEdit (record) {
  RefDistributeDrawer.value.showDrawer({
    id: record.id
  })
}

// 抛出
defineExpose({
  showDrawer
})

</script>

<style scoped>
.ant-btn-link {
  padding: 0;
}
</style>