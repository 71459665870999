<template>
  <!-- 剧集简介抽屉 -->
  <a-drawer
    :title="data && data.name || '第'+ data.eq_number + '集'"
    width="600"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :footer-style="{ textAlign: 'right' }"
  >
    <!-- 剧集简介 -->
    <a-textarea
      v-model:value="desc"
      placeholder="剧集简介"
      :rows="8"
      :disabled="isLoading"
    />
    <!-- 操作 -->
    <template #footer>
      <a-button type="primary" :loading="isLoading" @click="submit">保存</a-button>
    </template>
  </a-drawer>
</template>

<script setup>
import { ref, defineExpose } from 'vue'
import { videoDescription } from '@/api/copyright'
import { message } from 'ant-design-vue';

const emit = defineEmits(['success'])
// 抽屉展示状态
let visible = ref(false)
// 剧集信息
let data = ref({})
// 剧集简介
let desc = ref(undefined)
// 加载
let isLoading = ref(false)

// 打开抽屉
function showDrawer (record) {
  visible.value = true
  data.value = record
  // 单独取出剧集简介，防止修改父组件状态
  desc.value = record.description
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    visible.value = false
  }
}

// 保存剧情简介
function submit () {
  isLoading.value = true
  // 接口
  videoDescription({
    drama_id: data.value.drama_id,
    eq_number: data.value.eq_number,
    description: desc.value
  }).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      message.success('保存成功')
      emit('success')
      onClose()
    } else {
      message.error(res.message || msg)
    }
  })
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less">

</style>