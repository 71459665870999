<template>
  <div class="sync-cover-item">
    <span v-if="item.open_pic_id">已同步抖小</span>
    <span v-else>
      <a @click="touchSync(item)" v-if="!isLoading">同步至抖小</a>
      <span v-else>同步中...</span>
    </span>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { message } from 'ant-design-vue'
import { ttSyncImage as syncImage } from '@/api/copyright'

const props = defineProps({
  // 数据
  item: {
    type: Object,
    default: () => {}
  }
})
// 加载
let isLoading = ref(false)

// 点击同步
function touchSync () {
  isLoading.value = true
  syncImage({ url: props.item.url }).then(res => {
    const { code, data } = res
    if (code === 0) {
      props.item.open_pic_id = data.open_pic_id
    } else {
      message.error(res.message)
    }
    isLoading.value = false
  }).catch(err => {
    message.error('同步失败')
    isLoading.value = false
  })
}
</script>

<style scoped>
.sync-cover-item {
  font-size: 12px;
  color: #8D8D8D;
}
</style>