<template>
  <a-drawer
    :title="detail.remark_name"
    width="700"
    :visible="visible"
    :closable="false"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <!-- from -->
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 短剧推荐语 -->
      <a-form-item
        label="短剧推荐语"
        name="recommendation"
        :rules="[{ required: true }]"
      >
        <a-input
          v-model:value="formState.recommendation"
          placeholder="请输入（12字以内）"
          :maxlength="12"
        />
      </a-form-item>
      <!-- 抖小短剧类目 -->
      <a-form-item
        label="抖小短剧类目"
        name="tag"
        :rules="[{ required: true }]"
      >
        <a-select
          v-model:value="formState.tag"
          mode="multiple"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :filterOption="filterOption"
        >
          <a-select-option
            v-for="item in plotTags"
            :key="`${item.id}-${item.name}`"
            :value="item.id"
            :disabled="!formState.tag.includes(item.id) && formState.tag.length >= 3"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 备案号 -->
      <a-form-item
        label="备案号"
        name="ordinary_record_num"
        :rules="[{ required: true }]"
      >
        <a-input
          v-model:value="formState.ordinary_record_num"
          placeholder="请输入"
        />
      </a-form-item>
      <!-- 平均单集时长 -->
      <a-form-item
        label="平均单集时长"
        name="duration"
        :rules="[{ required: true, validator: validatorInt }]"
      >
        <a-input
          v-model:value="formState.duration"
          placeholder="请输入"
          suffix="分钟"
        />
      </a-form-item>
      <!-- 制作机构 -->
      <a-form-item
        label="制作机构"
        name="production_organisation"
        :rules="[{ required: true }]"
      >
        <a-input
          v-model:value="formState.production_organisation"
          placeholder="请输入"
        />
      </a-form-item>
      <!-- 导演 -->
      <a-form-item
        label="导演"
        name="directors"
        :rules="[{ required: true }]"
      >
        <a-input
          v-model:value="formState.directors"
          placeholder="请输入，有多个使用顿号间隔，例如 A、B、C"
        />
      </a-form-item>
      <!-- 制作人 -->
      <a-form-item
        label="制作人"
        name="producer"
        :rules="[{ required: true }]"
      >
        <a-input
          v-model:value="formState.producer"
          placeholder="请输入，有多个使用顿号间隔，例如 A、B、C"
        />
      </a-form-item>
      <!-- 编剧 -->
      <a-form-item
        label="编剧"
        name="screen_writer"
        :rules="[{ required: true }]"
      >
        <a-input
          v-model:value="formState.screen_writer"
          placeholder="请输入，有多个使用顿号间隔，例如 A、B、C"
        />
      </a-form-item>
      <!-- 内容梗概 -->
      <a-form-item
        label="内容梗概"
        name="summary"
        :rules="[{ required: true }]"
      >
        <a-textarea
          v-model:value="formState.summary"
          placeholder="请输入，1000字以内"
          :auto-size="{ minRows: 3, maxRows: 3 }"
          :maxlength="1000"
        />
      </a-form-item>
      <!-- 成本类型 -->
      <a-form-item
        label="成本类型"
        name="playlet_production_cost"
        :rules="[{ required: true }]"
      >
        <a-select
          v-model:value="formState.playlet_production_cost"
          placeholder="请选择"
        >
          <a-select-option :value="10">30万以下</a-select-option>
          <a-select-option :value="20">30-100万</a-select-option>
          <a-select-option :value="30">100万以上</a-select-option>
        </a-select>
      </a-form-item>
      <!-- 抖小成本比例 -->
      <a-form-item
        label="抖小成本比例"
        name="cost_distribution_uris"
        :rules="[{ required: true, validator: validatorImage }]"
      >
        <a-form-item-rest>
          <Upload
            accept=".png,.jpg,.gif"
            uploadSource="TtOperation1"
            :beforeUploadPro="beforeUploadPro"
            :uploadResult="(fileJson, res, record) => uploadResult('cost_distribution_uris', fileJson, res, record)"
            :fileList="formState.cost_distribution_uris"
            :uploadBucket="$pub.BUCKET_PUB()"
          />
        </a-form-item-rest>
        <div class="form-upload-hint">模板请从抖小官网下载。支持.png .jpg .gif</div>
        <div class="form-upload-list" v-if="formState.cost_distribution_uris.length">
          <ImageView
            class="form-upload-item"
            v-for="(item, index) in formState.cost_distribution_uris"
            :key="index"
            :url="$pub.CDN_URL(item.url)"
            :fileJson="item"
            @delete="touchDeleteCover('cost_distribution_uris', index)"
          >
          </ImageView>
        </div>
      </a-form-item>
      <!-- 承诺书 -->
      <a-form-item
        label="承诺书"
        name="assurance_uris"
        :rules="[{ required: true, validator: validatorImage }]"
      >
        <a-form-item-rest>
          <Upload
            accept=".png,.jpg,.gif"
            uploadSource="TtOperation2"
            :beforeUploadPro="beforeUploadPro"
            :uploadResult="(fileJson, res, record) => uploadResult('assurance_uris', fileJson, res, record)"
            :fileList="formState.assurance_uris"
            :uploadBucket="$pub.BUCKET_PUB()"
          />
        </a-form-item-rest>
        <div class="form-upload-hint">模板请从抖小官网下载。支持.png .jpg .gif</div>
        <div class="form-upload-list" v-if="formState.assurance_uris.length">
          <ImageView
            class="form-upload-item"
            v-for="(item, index) in formState.assurance_uris"
            :key="index"
            :url="$pub.CDN_URL(item.url)"
            :fileJson="item"
            @delete="touchDeleteCover('assurance_uris', index)"
          >
          </ImageView>
        </div>
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
    <!-- 预览 -->
    <PreviewModal ref="RefPreviewModal"></PreviewModal>
  </a-drawer>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { nextTick } from 'process'
import Loading from '@/components/Loading'
import { dySyncCreate, dySyncEdit, dyTags } from '@/api/copyright'
import PreviewModal from '@/components/preview/PreviewModal'
import { message, Modal } from 'ant-design-vue'
import Pub from '@/utils/public'
import Upload from '@/components/Upload'
import ImageView from '@/components/ImageView'

// 视频预览
let RefPreviewModal = ref(null)
// emit
const emit = defineEmits(['success'])
// from
const RefForm = ref(null)
// 显示
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 详情
let detail = ref({})
// 剧情标签列表
let plotTags = ref([])
// 表单参数
const formState = reactive({
  // 抖小短剧类目
  tag: [],
  // 短剧推荐语
  recommendation: undefined,
  // 备案号
  ordinary_record_num: undefined,
  // 平均单集时长
  duration: undefined,
  // 制作机构
  production_organisation: undefined,
  // 导演
  directors: undefined,
  // 制作人
  producer: undefined,
  // 编剧
  screen_writer: undefined,
  // 内容梗概
  summary: undefined,
  // 成本类型
  playlet_production_cost: undefined,
  // 抖小成本比例
  cost_distribution_uri: undefined,
  cost_distribution_uris: [],
  // 承诺书
  assurance_uri: undefined,
  assurance_uris: []
})

// 显示
function showDrawer (data) {
  // 记录
  detail.value = data
  // 显示
  visible.value = true
  // 延迟处理
  nextTick(() => {
    // 获取标签
    getPlotTags()
    // 赋值
    formState.tag = data.tag || []
    formState.recommendation = data.recommendation
    formState.ordinary_record_num = data.ordinary_record_num
    formState.duration = data.duration
    formState.production_organisation = data.production_organisation
    formState.directors = data.directors
    formState.producer = data.producer
    formState.screen_writer = data.screen_writer
    formState.summary = data.summary
    formState.playlet_production_cost = data.playlet_production_cost
    if (data.cost_distribution_uri) {
      formState.cost_distribution_uri = data.cost_distribution_uri
      formState.cost_distribution_uris = [{ url: data.cost_distribution_uri}]
    }
    if (data.assurance_uri) {
      formState.assurance_uri = data.assurance_uri
      formState.assurance_uris = [{ url: data.assurance_uri}]
    }
  })
}

// 模糊匹配
function filterOption (input, option) {
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 关闭
function onClose () {
  if (!isLoading.value) {
    resetFields()
    visible.value = false
  }
}

// 重置
function resetFields () {
  RefForm.value.resetFields()
  // formState.value.tag = []
  // formState.value.recommendation = undefined
  // formState.value.ordinary_record_num = undefined
}

// 准备上传（图片）
function beforeUploadPro (file) {
  if (!file.type.includes('image')) {
    message.error('请上传图片类型的文件')
    return false
  }
  return true
}

// 上传结果
function uploadResult (key, fileJson, res, record) {
  // 上传结果判断
  if (fileJson.status === 'done') {
    RefForm.value.validate()
    formState[key] = [fileJson]
  }
}

// 图片校验
function validatorImage (rule, value) {
  // 图片
  if (value && value.length === 0) {
    return Promise.reject('请上传')
  }
  // 都通过
  return Promise.resolve()
}

// 移除封面
function touchDeleteCover (coverKey, index) {
  formState[coverKey].splice(index, 1)
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then((res => {
    const params = {
      id: detail.value && detail.value.id,
      ...res,
    }
    // 上传数据
    if (formState.cost_distribution_uris.length) {
      const imgs = formState.cost_distribution_uris || []
      const isUploadSuccess = imgs.every(item => {
        return item.status === undefined || item.status === 'done'
      })
      if (!isUploadSuccess) {
        message.error('正在上传中...')
        return
      }
      params.cost_distribution_uri = formState.cost_distribution_uris[0].url
    }
    if (formState.assurance_uris.length) {
      const imgs = formState.assurance_uris || []
      const isUploadSuccess = imgs.every(item => {
        return item.status === undefined || item.status === 'done'
      })
      if (!isUploadSuccess) {
        message.error('正在上传中...')
        return
      }
      params.assurance_uri = formState.assurance_uris[0].url
    }
    isLoading.value = true
    // 创建了短剧
    if (detail.value.douyin_drama_id) {
      // 编辑
      dySyncEdit({ type: 1, ...params }).then((res) => {
        isLoading.value = false
        const { code, data } = res
        if (code === 0 && (data ? data.err_no === 0 : true)) {
          message.success('保存成功')
          onClose()
          emit('success')
        } else {
          Modal.error({
            title: '操作错误',
            content: (data && data.err_msg) || res.message
          })
        }
      }).catch(() => {
        isLoading.value = false
        Modal.error({
          title: '操作错误',
          content: '保存失败'
        })
      })
    } else {
      // 创建
      dySyncCreate(params).then((res) => {
        isLoading.value = false
        const { code, data } = res
        if (code === 0 && (data ? data.err_no === 0 : true)) {
          message.success('保存成功')
          onClose()
          emit('success')
        } else {
          Modal.error({
            title: '操作错误',
            content: (data && data.err_msg) || res.message
          })
        }
      }).catch(() => {
        isLoading.value = false
        Modal.error({
          title: '操作错误',
          content: '保存失败'
        })
      })
    }
  })).catch((err) => {
    console.log(err)
  })
}

// 获取标签列表
function getPlotTags () {
  const params = {
    page: 1,
    page_size: 10000
  }
  dyTags(params).then(res => {
    const { code, data } = res
    if (code === 0) {
      plotTags.value = data
    }
  })
}

// 效验正整数
function validatorInt (rule, value) {
  if (!value) {
    return Promise.reject('请输入${label}')
  } else if (!Pub.REG_IS_POSITIVE_INTEGER(value)) {
    return Promise.reject('必须为正整数')
  }
  return Promise.resolve()
}

// 抛出
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.form-input {
  width: 50%;
}
.form-item-clear {
  margin-bottom: 0;
}
.form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  width: 118px;
}
.image-view.upload-image {
  height: 148px;
}
.sync-tt {
  padding-top: 6px;
  font-size: 12px;
  color: rgba(232, 230, 227, 0.45);
}
.form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
// .form-upload-item {
//   margin-top: 10px;
//   margin-left: 10px;
//   position: relative;
//   width: 118px;
//   height: 148px;
//   border-radius: 4px;
//   overflow: hidden;
//   img {
//     display: inline-block;
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//   }
//   &.horizontal {
//     width: 148px;
//     height: 118px;
//   }
// }
.image-view.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  width: 118px;
  height: 148px;
  &.horizontal {
    width: 148px;
    height: 118px;
  }
}
</style>