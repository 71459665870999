<template>
  <a-drawer
    title="分发"
    width="700"
    :visible="visible"
    :closable="false"
    @close="onClose"
  >
    <!-- from -->
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 剧情类型 -->
      <a-form-item
        label="分发项目"
        name="project"
        :rules="[{ required: true }]"
      >
        <a-select
          v-model:value="formState.project"
          placeholder="请选择"
          mode="multiple"
          show-search
          :disabled="!!recordID"
          :filter-option="filterOption"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"

        >
          <a-select-option
            v-for="item in pList"
            :key="`${item.id}-${item.name}`"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 结算方式 -->
      <a-form-item
        label="合作方式"
        name="settlement"
        :rules="[{ required: true }]"
      >
        <a-select class="form-input" v-model:value="formState.settlement" placeholder="请选择">
          <a-select-option
            v-for="item in CashTypes"
            :key="item.value"
            :value="item.value"
          >
            {{ item.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 价格 -->
      <a-form-item
        label="价格"
        name="price"
        :rules="[{ required: true, validator: validatorPrice }]"
      >
        <a-input
          class="form-input"
          v-model:value="formState.price"
          placeholder="请输入"
          suffix="元"
        />
      </a-form-item>
      <!-- 分成比例 -->
      <a-form-item
        label="分成比例"
        name="proportion"
        :rules="[{ required: true, validator: validatorProportion }]"
        v-if="formState.settlement === 2"
      >
        <a-input
          class="form-input"
          v-model:value="formState.proportion"
          placeholder="请输入"
          suffix="%"
        />
      </a-form-item>
      <!-- 版权期限开始 -->
      <a-form-item
        label="授权开始"
        name="copyrightTermStart"
        :rules="[{ required: true }]"
      >
        <a-date-picker
          class="form-input"
          v-model:value="formState.copyrightTermStart"
          :format="dateFormat"
          :disabled="!!recordID"
          :disabledDate="disabledDate"
        />
      </a-form-item>
      <!-- 版权期限结束 -->
      <a-form-item
        label="授权结束"
        name="copyrightTermEnd"
        :rules="[{ required: true }]"
      >
        <a-date-picker
          class="form-input"
          v-model:value="formState.copyrightTermEnd"
          :format="dateFormat"
          :disabledDate="disabledDate"
        />
      </a-form-item>
      <!-- 独家授权 -->
      <a-form-item
        label="独家授权"
        name="exclusive"
      >
        <a-radio-group class="form-input" v-model:value="formState.exclusive" :disabled="disabledExclusive">
          <a-radio
            v-for="item in ExclusiveTypes"
            :key="item.value"
            :value="item.value"
          >
            {{ item.text }}
          </a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <div class="footer-view">
        <span class="footer-hint" v-if="selectKeys.length > 1">共选择了{{ selectKeys.length }} 部短剧</span>
        <div style="flex: 1;"></div>
        <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
        <a-button type="primary" @click="touchSubmit">确定</a-button>
      </div>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { nextTick } from 'process'
import { message } from 'ant-design-vue'
import { CashTypes, ExclusiveTypes } from '@/utils/constantList'
import Loading from '@/components/Loading'
import Pub from '@/utils/public'
import dayjs from 'dayjs'
import { dramaDistribute, projectList, distributeDetail, distributeEdit } from '@/api/copyright'

// emit
const emit = defineEmits(['success'])
// from
const RefForm = ref(null)
// 显示
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 选中的影剧列表
let selectKeys = ref([])
// 编辑的分发记录id
let recordID = ref(undefined)
// 项目列表
let pList= ref([])
// 日期格式
const dateFormat = 'YYYY-MM-DD'
// 表单参数
const formState = reactive({
  // 分发项目
  project: [],
  // 结算方式
  settlement: undefined,
  // 价格
  price: undefined,
  // 分成比例
  proportion: undefined,
  // 版权期限开始
  copyrightTermStart: undefined,
  // 版权期限结束
  copyrightTermEnd: undefined,
  // 独家授权
  exclusive: 0
})
// 固定的独家id-0，不可修改
let disabledExclusive = ref(false)

// 显示
function showDrawer ({ keys, id, fixedExclusive }) {
  // 多选新建分发时，用到 keys
  selectKeys.value = keys || []
  // 单个分发记录编辑时，用到id
  recordID.value = id || undefined
  // fixedExclusive：固定的独家id，且不可修改
  if (fixedExclusive !== undefined) {
    formState.exclusive = fixedExclusive
    disabledExclusive.value = true
  } else {
    disabledExclusive.value = false
  }
  // 显示
  visible.value = true
  // 获取项目列表
  getProjectList()
  // 获取分发详情
  if (id) {
    getDistributeDetail()
  }
}

// 不可选中当天之前的日期（今日可选中）
function disabledDate (current) {
  return current && current < dayjs().subtract(1, 'days').endOf('day')
}

// 获取项目列表
function getProjectList () {
  projectList().then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      pList.value = data
    } else {
      message.error(res.message || msg)
    }
  })
}

// 获取分发详情
function getDistributeDetail () {
  distributeDetail({
    id: recordID.value
  }).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      // 设置表单显示
      nextTick(() => {
        // 分发项目
        formState.project = data.project_ids
        // 合作方式
        formState.settlement = data.cop_type
        // 价格
        formState.price = Pub.KEEP_NUMBER_DECIMAL(data.money / 100, 2)
        // 分成比例
        formState.proportion = data.cop_type === 1 ? undefined : data.cop_rate
        // 授权开始
        formState.copyrightTermStart = dayjs(data.copyright_start_at)
        // 授权结束
        formState.copyrightTermEnd = dayjs(data.copyright_end_at)
        // 是否独家
        formState.exclusive = data.is_unique_auth
      })
    } else {
      message.error(res.message || msg)
    }
  })
}

// 关闭
function onClose () {
  if (!isLoading.value) {
    visible.value = false
    resetFields()
  }
}

// 重置
function resetFields () {
  RefForm.value.resetFields()
}

// 模糊匹配
function filterOption (input, option) {
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then(() => {
    isLoading.value = true
    let params = {}
    if (recordID.value) {
      // 编辑分发
      params = {
        id: recordID.value,
        cop_type: formState.settlement,
        money: formState.price * 100,
        cop_rate: formState.proportion,
        copyright_end_at: formState.copyrightTermEnd.format(dateFormat),
        is_unique_auth: formState.exclusive,
      }
    } else {
      // 新建分发
      params = {
        project_ids: formState.project,
        drama_ids: selectKeys.value,
        cop_type: formState.settlement,
        money: formState.price * 100,
        cop_rate: formState.proportion,
        copyright_start_at: formState.copyrightTermStart.format(dateFormat),
        copyright_end_at: formState.copyrightTermEnd.format(dateFormat),
        is_unique_auth: formState.exclusive,
      }
    }
    // 按需执行函数
    const Func = recordID.value ? distributeEdit : dramaDistribute
    Func(params).then(res => {
      isLoading.value = false
      const { code, msg, data } = res
      if (code === 0) {
        message.success('操作成功')
        onClose()
        emit('success')
      } else {
        message.error(res.message || msg)
      }
    })
  }).catch((err) => {
    console.log(err)
  })
}

// 效验 - 价格
function validatorPrice (rule, value) {
  if (!value) {
    return Promise.reject('请输入${label}')
  } else if (!Pub.REG_IS_INTEGER_OR_FLOAT(value)) {
    return Promise.reject('必须为正数，且最多保留两位小数')
  }
  return Promise.resolve()
}
// 效验 - 分成比例
function validatorProportion (rule, value) {
  if (!value) {
    return Promise.reject('请输入${label}')
  } else if (!Pub.REG_IS_NUMBER(value)) {
    return Promise.reject('必须为正数')
  } else if (Pub.CHECK_NUMBER_DECIMAL(value, 2)) {
    return Promise.reject('最多 2 位小数')
  }
  return Promise.resolve()
}

// 抛出
defineExpose({
  showDrawer
})

</script>

<style scoped>
.form-input {
  width: 50%;
}
.form-item-clear {
  margin-bottom: 0;
}
.footer-view {
  display: flex;
  align-items: center;
}
.footer-hint {
  color: #5A5A5A;
}
</style>