<template>
  <a-drawer
    :title="videoJson && `${videoJson.remark_name}（${videoJson.total_ep}集）`"
    width="700"
    :visible="visible"
    :closable="false"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 3 }"
      :wrapper-col="{ span: 18 }"
    >
      <a-form-item
        v-for="(item, index) in formState.list"
        :label="`短剧${index + 1}`"
        :name="['list', index, 'id']"
        :rules="[{ required: true, message: '请选择' }]"
      >
        <a-select
          style="width: 50%;"
          show-search
          v-model:value="item.id"
          placeholder="请选择"
          :filter-option="filterOption"
        >
          <a-select-option
            v-for="item in dramas"
            :key="`${item.id}-${item.remark_name}`"
            :value="item.id"
          >
            {{ item.remark_name }}
          </a-select-option>
        </a-select>
        <a @click="touchDelete(index)" style="margin-left: 12px;">删除</a>
      </a-form-item>
      <!-- 新增 -->
      <a @click="touchAdd" style="margin-left: 80px;">+ 新增短剧</a>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px;" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { reactive, ref, createVNode } from 'vue'
import { nextTick } from 'process'
import { message } from 'ant-design-vue'
import { dramaAll, videoExport } from '@/api/copyright'
import Loading from '@/components/Loading'

// emit
const emit = defineEmits(['success'])
// from
const RefForm = ref(null)
// 视频信息
let videoJson = ref(null)
// 显示
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 短剧列表
let dramas = ref([])
// 表单
let formState = reactive({
  // 列表
  list: []
})

// 显示
function showDrawer (data) {
  console.log(videoJson)
  // 记录
  videoJson.value = data
  // 显示
  visible.value = true
  // 延迟处理
  nextTick(() => {
    // 初始化一个
    touchAdd()
    // 获取短剧列表
    getProjectDramaAll()
  })
}

// 关闭
function onClose () {
  if (!isLoading.value) {
    // 重置
    visible.value = false
    formState.list.length = 0
  }
}

// 短剧列表
function getProjectDramaAll () {
  dramaAll().then(res => {
    const { code, data } = res
    if (code === 0) {
      dramas.value = data
    }
  })
}

// 新增短剧
function touchAdd () {
  formState.list.push({
    id: undefined
  })
}

// 移除短剧
function touchDelete (index) {
  formState.list.splice(index, 1)
}

// 模糊匹配
function filterOption (input, option) {
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 提交
function touchSubmit () {
  if (formState.list.length) {
    RefForm.value.validate().then(() => {
      isLoading.value = true
      const ids = []
      formState.list.forEach(item => {
        ids.push(item.id)
      })
      const params = {
        drama_id: videoJson.value.id,
        export_drama_ids: ids
      }
      videoExport(params).then(res => {
        isLoading.value = false
        const { code } = res
        if (code === 0) {
          message.success('提交成功')
          emit('success')
          onClose()
        } else {
          message.error(res.message)
        }
      }).catch(() => {
        isLoading.value = false
        message.error('提交失败')
      })
    })
  } else {
    message.error('请添加短剧')
  }
}

// 抛出
defineExpose({
  showDrawer
})

</script>

<style scoped>
</style>