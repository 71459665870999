<template>
  <a-drawer
    :title="detail.remark_name"
    width="700"
    :visible="visible"
    :closable="false"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <!-- from -->
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 剧目资质 -->
      <a-form-item
        label="剧目资质"
        name="qualification_type"
        :rules="[{ required: true }]"
      >
        <a-select
          v-model:value="formState.qualification_type"
          placeholder="请选择"
        >
          <a-select-option :value="1">30万以上</a-select-option>
          <a-select-option :value="2">30万以下</a-select-option>
        </a-select>
      </a-form-item>
      <!-- 备案号 -->
      <a-form-item
        v-if="formState.qualification_type === 1"
        label="备案号"
        name="ordinary_record_num"
        :rules="[{ required: true }]"
      >
        <a-input
          v-model:value="formState.ordinary_record_num"
          placeholder="请输入"
        />
      </a-form-item>
      <!-- 制作成本 -->
      <a-form-item
        v-if="formState.qualification_type === 2"
        label="制作成本"
        name="cost_of_production"
        :rules="[{ required: true, validator: validatorInt }]"
      >
        <a-input
          v-model:value="formState.cost_of_production"
          placeholder="请输入"
          suffix="万元"
        />
      </a-form-item>
      <!-- 资质证明 -->
      <a-form-item
        v-if="formState.qualification_type === 1"
        label="资质证明"
        name="qualification_certificate_material_ids"
        :rules="[{ required: true, validator: validatorImage }]"
      >
        <a-form-item-rest>
          <Upload
            accept=".png,.jpg,.gif"
            uploadSource="WxOperation2"
            :beforeUploadPro="beforeUploadPro"
            :uploadResult="(fileJson, res, record) => uploadResult('qualification_certificate_material_ids', fileJson, res, record)"
            :fileList="formState.qualification_certificate_material_ids"
            :uploadBucket="$pub.BUCKET_PUB()"
          />
        </a-form-item-rest>
        <div class="form-upload-hint">模板请从微小官网下载。支持.png .jpg .gif</div>
        <div class="form-upload-list" v-if="formState.qualification_certificate_material_ids.length">
          <ImageView
            class="form-upload-item"
            v-for="(item, index) in formState.qualification_certificate_material_ids"
            :key="index"
            :url="$pub.CDN_URL(item.url)"
            :fileJson="item"
            @delete="touchDeleteCover('qualification_certificate_material_ids', index)"
          >
          </ImageView>
        </div>
      </a-form-item>
      <!-- 微小成本比例 -->
      <a-form-item
        v-if="formState.qualification_type === 2"
        label="微小成本比例"
        name="cost_commitment_letter_material_ids"
        :rules="[{ required: true, validator: validatorImage }]"
      >
        <!-- <a-form-item-rest>
          <Upload
            accept=".doc,.docx"
            uploadSource="WxOperation1"
            uploadType="al"
            :beforeUploadPro="beforeUploadPro"
            :uploadResult="(fileJson, res, record) => uploadResult('cost_commitment_letter_material_ids', fileJson, res, record)"
            :fileList="formState.cost_commitment_letter_material_ids"
            :uploadBucket="$pub.BUCKET_PUB()"
            :showUploadList="true"
          />
        </a-form-item-rest>
        <div class="form-upload-hint">模板请从微小官网下载。支持.doc .docx</div> -->
        <a-form-item-rest>
          <Upload
            accept=".png,.jpg,.gif"
            uploadSource="WxOperation1"
            :beforeUploadPro="beforeUploadPro"
            :uploadResult="(fileJson, res, record) => uploadResult('cost_commitment_letter_material_ids', fileJson, res, record)"
            :fileList="formState.cost_commitment_letter_material_ids"
            :uploadBucket="$pub.BUCKET_PUB()"
          />
        </a-form-item-rest>
        <div class="form-upload-hint">模板请从微小官网下载。支持.png .jpg .gif</div>
        <div class="form-upload-list" v-if="formState.cost_commitment_letter_material_ids.length">
          <ImageView
            class="form-upload-item"
            v-for="(item, index) in formState.cost_commitment_letter_material_ids"
            :key="index"
            :url="$pub.CDN_URL(item.url)"
            :fileJson="item"
            @delete="touchDeleteCover('cost_commitment_letter_material_ids', index)"
          >
          </ImageView>
        </div>
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
    <!-- 预览 -->
    <PreviewModal ref="RefPreviewModal"></PreviewModal>
  </a-drawer>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { nextTick } from 'process'
import Loading from '@/components/Loading'
import { wxEditDrama } from '@/api/copyright'
import PreviewModal from '@/components/preview/PreviewModal'
import { message, Modal } from 'ant-design-vue'
import Pub from '@/utils/public'
import Upload from '@/components/Upload'
import ImageView from '@/components/ImageView'

// 视频预览
let RefPreviewModal = ref(null)
// emit
const emit = defineEmits(['success'])
// from
const RefForm = ref(null)
// 显示
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 详情
let detail = ref({})
// 表单参数
const formState = reactive({
  // 剧目资质
  qualification_type: undefined,
  // 备案号
  ordinary_record_num: undefined,
  // 制作成本
  cost_of_production: undefined,
  // 资质证明
  cost_commitment_letter_material_id: undefined,
  cost_commitment_letter_material_ids: [],
  // 微小成本比例
  qualification_certificate_material_id: undefined,
  qualification_certificate_material_ids: []
})

// 显示
function showDrawer (data) {
  // 记录
  detail.value = data
  // 显示
  visible.value = true
  // 延迟处理
  nextTick(() => {
    // 赋值
    formState.qualification_type = data.qualification_type
    formState.ordinary_record_num = data.ordinary_record_num
    formState.cost_of_production = data.cost_of_production
    if (data.cost_commitment_letter_material_id) {
      formState.cost_commitment_letter_material_id = data.cost_commitment_letter_material_id
      formState.cost_commitment_letter_material_ids = [{ url: data.cost_commitment_letter_material_id}]
    }
    if (data.qualification_certificate_material_id) {
      formState.qualification_certificate_material_id = data.qualification_certificate_material_id
      formState.qualification_certificate_material_ids = [{ url: data.qualification_certificate_material_id}]
    }
  })
}

// 模糊匹配
function filterOption (input, option) {
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 关闭
function onClose () {
  if (!isLoading.value) {
    resetFields()
    visible.value = false
  }
}

// 重置
function resetFields () {
  RefForm.value.resetFields()
  formState.qualification_type = undefined
  formState.ordinary_record_num = undefined
  formState.cost_of_production = undefined
  formState.cost_commitment_letter_material_ids = []
  formState.qualification_certificate_material_ids = []
}

// 准备上传（图片）
function beforeUploadPro (file) {
  if (formState.qualification_type === 1 && !file.type.includes('image')) {
    message.error('请上传图片类型的文件')
    return false
  }
  return true
}

// 上传结果
function uploadResult (key, fileJson, res, record) {
  // 上传结果判断
  if (fileJson.status === 'done') {
    RefForm.value.validate()
    formState[key] = [fileJson]
  }
}

// 图片校验
function validatorImage (rule, value) {
  // 图片
  if (value && value.length === 0) {
    return Promise.reject('请上传')
  }
  // 都通过
  return Promise.resolve()
}

// 移除封面
function touchDeleteCover (coverKey, index) {
  formState[coverKey].splice(index, 1)
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then((res => {
    const params = {
      drama_id: detail.value && detail.value.id,
      ...res,
    }
    // 上传数据
    if (formState.cost_commitment_letter_material_ids.length) {
      const imgs = formState.cost_commitment_letter_material_ids || []
      const isUploadSuccess = imgs.every(item => {
        return item.status === undefined || item.status === 'done'
      })
      if (!isUploadSuccess) {
        message.error('正在上传中...')
        return
      }
      params.cost_commitment_letter_material_id = formState.cost_commitment_letter_material_ids[0].url
    }
    if (formState.qualification_certificate_material_ids.length) {
      const imgs = formState.qualification_certificate_material_ids || []
      const isUploadSuccess = imgs.every(item => {
        return item.status === undefined || item.status === 'done'
      })
      if (!isUploadSuccess) {
        message.error('正在上传中...')
        return
      }
      params.qualification_certificate_material_id = formState.qualification_certificate_material_ids[0].url
    }
    isLoading.value = true
    // 编辑
    wxEditDrama(params).then((res) => {
      isLoading.value = false
      const { code, data } = res
      if (code === 0) {
        message.success('保存成功')
        onClose()
        emit('success')
      } else {
        Modal.error({
          title: '操作错误',
          content: res.message
        })
      }
    }).catch(() => {
      isLoading.value = false
      Modal.error({
          title: '操作错误',
          content: '保存失败'
        })
    })
  })).catch((err) => {
    console.log(err)
  })
}

// 效验正整数
function validatorInt (rule, value) {
  if (!value) {
    return Promise.reject('请输入${label}')
  } else if (!Pub.REG_IS_POSITIVE_INTEGER(value)) {
    return Promise.reject('必须为正整数')
  } else if (value < 1 || value > 29) {
    return Promise.reject('取值范围 1 ~ 29')
  }
  return Promise.resolve()
}

// 抛出
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.form-input {
  width: 50%;
}
.form-item-clear {
  margin-bottom: 0;
}
.form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  width: 118px;
}
.image-view.upload-image {
  height: 148px;
}
.sync-tt {
  padding-top: 6px;
  font-size: 12px;
  color: rgba(232, 230, 227, 0.45);
}
.form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
// .form-upload-item {
//   margin-top: 10px;
//   margin-left: 10px;
//   position: relative;
//   width: 118px;
//   height: 148px;
//   border-radius: 4px;
//   overflow: hidden;
//   img {
//     display: inline-block;
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//   }
//   &.horizontal {
//     width: 148px;
//     height: 118px;
//   }
// }
.image-view.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  width: 118px;
  height: 148px;
  &.horizontal {
    width: 148px;
    height: 118px;
  }
}
</style>